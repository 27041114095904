// store.js
import { reactive } from 'vue';

export const store = reactive({
	config: {},
	setConfig: (config) => {
		store.config = config;
	},
	homeIndexCurrent: 1,
	setHomeIndexCurrent: (value) => {
		store.homeIndexCurrent = value;
	}
});
