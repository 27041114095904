import { createRouter, createWebHashHistory } from "vue-router";

export const routes = [
  {
    path: "/",
    redirect: "/home",
    component: () => import("../layout/index.vue"),
    children: [
      {
        path: "/home",
        component: () => import("../views/home/index"),
        meta: {
          title: "首页",
          keepAlive: true,
        },
      },
      {
        path: "/details",
        component: () => import("../views/details/index"),
        meta: {
          title: "详情",
          keepAlive: false,
        },
      },
      {
        path: "/more",
        component: () => import("../views/more/index"),
        meta: {
          title: "更多",
          keepAlive: false,
        },
      },
      {
        path: "/login",
        component: () => import("../views/login/index"),
        meta: {
          title: "登陆",
          keepAlive: false,
        },
      },
    ],
  },
  // 匹配不到重定向会主页
  // {
  //   // 找不到路由重定向到404页面
  //   path: "/:pathMatch(.*)",
  //   redirect: "/",
  // },
];

export default createRouter({
  history: createWebHashHistory(),
  scrollBehavior() {
    // 始终滚动到顶部
    return { top: 0 };
  },
  routes,
});
